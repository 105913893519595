<template>
	<tr :class="{'table-danger': isHoliday }" class="redmine-table">
		<td v-bind:key="index" v-for="(rowData, index) in rowItems" :class="[index, {'text': !isInput(index) && !isCheckbox(index)} ]" class="align-middle">
			<div v-if="isCheckbox(index)" class="checkbox">
				<input type="checkbox" @change="toggleValue('rowitem['+ rowItems.index +']'+index)" :name="'rowitem['+ rowItems.index +']'+index" :disabled="isHoliday" :checked="checkedItem(rowItems)"  :value="checkedValue(rowItems)"/>
				<div class="checkbox-visible"></div>
			</div>
			<div v-else-if="isInput(index)" class="input">
				<textarea type="text" :name="'rowitem['+ rowItems.index +']'+index" :value="rowData" :disabled="isHoliday"/>
			</div>
			<div v-else-if="isInputNumber(index)" class="input">
				<input type="number" step=".01" :name="'rowitem['+ rowItems.index +']'+index" :value="rowData" :disabled="isHoliday"/>
			</div>
			<div v-else-if="index == 'day'" class="button">
				<button class="btn btn-success add-to-sheet" @click="addRow(rowItems)" v-show="isButtonVisible">Add to Sheet</button>
				<button class="btn btn-info ml-2" v-if="hasDateTag(rowItems['comments'])" @click="updateDate(rowItems)">Update Date</button>
			</div>
			<div v-else-if="index == 'date'" class="input">
				<input type="text" class="date-field" :name="'rowitem['+ rowItems.index +']'+index" v-model="rowItems.date" :disabled="isHoliday"/>
			</div>
			<div v-else class="text-item">
				<div v-if="index == 'ticketNumber'"><a class="ticket-link" :href="`https://projects.zyxware.net/issues/${rowData}`" target="_blank">{{rowData}}</a></div>
				<div v-else-if="index == 'index'">{{rowData + 1}}</div>
				<div v-else>{{rowData}}</div>
				<input v-if="index !== 'index'" type="hidden" :name="'rowitem['+ rowItems.index +']'+index" :value="rowData">
			</div>
		</td>
	</tr>
</template>

<script>
	import userdata from '@/userdata.json';
	import { GoogleSpreadsheet } from 'google-spreadsheet';
	import creds from '@/client_secret.json';
	export default {
		name: "RedmineRow",
		props: ["row"],
		data() {
			return {
				isButtonVisible: false,
			};
		},
		computed: {
			rowItems() {
				return this.row
			},
			isHoliday() {
				return (this.row.day === 'Saturday' || this.row.day === 'Sunday')
			}
		},
		methods: {
			hasDateTag(value) {
				const regex = /^\[\d{1,2}-\d{1,2}-\d{2,4}\]/;
				return regex.test(value);
			},
			async updateDate(rowItems) {
				const regex = /^\[(\d{1,2})-(\d{1,2})-(\d{2,4})\]/;
				const match = rowItems['comments'].match(regex);
				// Select all input elements with name attribute containing "rowitem[2]"
				const elements = this.$el.querySelectorAll('input[name^="rowitem"], textarea[name^="rowitem"]');
				// Loop through the selected elements and retrieve their values
				const values = [];
				elements.forEach(element => {
					values.push(element.value);
				});

				rowItems.actualHours = values[6];
				rowItems.billableHours = values[7];
				rowItems.comments = values[8];

				if (match) {
					const year = match[3].length === 2 ? '20' + match[3] : match[3];
					const month = match[2].padStart(2, '0');
					const day = match[1].padStart(2, '0');
					this.$emit('update-date', { ...rowItems, date: `${day}/${month}/${year}` });
				}
			},
			toggleValue(element) {
				const elementItem = this.$el.querySelector(`input[name='${element}']`);
				elementItem.value = elementItem.value === 'Yes' ? 'No' : 'Yes';
			},
			checkedItem (rowItems) {
				if(!rowItems.projectCode) return false
				return (!this.nonBillable(rowItems) || !this.nonIsClient(rowItems)) && !this.isHoliday
			},
			checkedValue(rowItems) {
				if(!rowItems.projectCode) return ''
				return ((!this.nonBillable(rowItems) || !this.nonIsClient(rowItems)) && !this.isHoliday) ? 'Yes' : 'No'
			},
			isCheckbox(index) {
				const checkboxItems = [
					'isClient',
					'isBillable',
				];
				return checkboxItems.includes(index)
			},
			isInput(index) {
				const textItems = [
					'comments'
				];
				return textItems.includes(index)	
			},
			isInputNumber(index) {
				const textItems = [
					'billableHours',
					'actualHours',
				];
				return textItems.includes(index)	
			},
			nonBillable(rowItems) {
				const pCode = rowItems.projectCode || ''
				const nonBillablepCodes = [
					'OB-Organisation Bench',
					'Zi-Zyxwared8',
					'Zi-CustomerValuePropositions',
					'Zyxware Software Projects',
					'Zi - Drupal Contributions',
					'Z - Lecture Series',
					'Zi - Hiring - interviews - tests etc',
					'Z - Unproductive - Power Internet and System Failure',
					'Z - General Administration',
					'Z - IT Services & Infrastructure',
					'Zi-Redmine for Effectiveness',
					'ZI-Knowledge Portal',
				]
				if (pCode) {
					return nonBillablepCodes.includes(pCode)
				}
			},
			nonIsClient(rowItems) {
				const pCode = rowItems.projectCode || ''
				const nonBillablepCodes = [
					'OB-Organisation Bench',
					'Zi-Zyxwared8',
					'Zi-CustomerValuePropositions',
					'Zyxware Software Projects',
					'Zi - Drupal Contributions',
					'Z - Lecture Series',
					'Zi - Hiring - interviews - tests etc',
					'Z - Unproductive - Power Internet and System Failure',
					'Z - General Administration',
					'Z - IT Services & Infrastructure',
					'Zi-Redmine for Effectiveness',
					'ZI-Knowledge Portal',
				]
				if (pCode) {
					return nonBillablepCodes.includes(pCode)
				}
			},
			findNameById(array, id) {
				const result = array.find(item => item.id === id);
				return result ? result.name : null;
			},
			async addRow() {
				// Select all input elements with name attribute containing "rowitem[2]"
				const elements = this.$el.querySelectorAll('input[name^="rowitem"], textarea[name^="rowitem"]');

				// Loop through the selected elements and retrieve their values
				const values = [];
				elements.forEach(element => {
					values.push(element.value);
				});

				const userId = this.$route.params.redmineid
				const userName = this.findNameById(userdata.users, userId)

				// Parse the date string into a Date object
				const rowDate = values[0];

				// Split the date string into day, month, and year components
				const [day, month, year] = rowDate.split('/').map(Number);
				// Create a Date object with the extracted components (subtract 1 from month as it's 0-based)
				const date = new Date(year, month - 1, day);

				// Get the month as a number (0-based index)
				const monthNumber = date.getMonth();
				const yearNumber = date.getFullYear();

				// Create an array of month names
				const monthNames = [
					"January", "February", "March", "April", "May", "June",
					"July", "August", "September", "October", "November", "December"
				];

				// Get the month name
				const monthName = monthNames[monthNumber];

				try {
					const doc = new GoogleSpreadsheet(userdata.googleSheetId);
					await doc.useServiceAccountAuth(creds);
					await doc.loadInfo();
					const sheetName = userName + ' - ' + monthName + ' ' + yearNumber
					const sheet = doc.sheetsByTitle[sheetName];
					if (!sheet) {
						const dummySheet = doc.sheetsByTitle['DoNotEditOrTouch'];
						let duplicateSheet = await dummySheet.duplicate()
						await duplicateSheet.updateProperties({title: sheetName})
						await duplicateSheet.addRow(values);
						this.$toast.success(`Sheet Created and added to ${sheetName} !!!`, { position: 'bottom-right' });
					}
					else {
						//const sheet = doc.sheetsByIndex[sheetIndex];
						await sheet.addRow(values);
						this.$toast.success(`Added to ${sheetName} !!!`, { position: 'bottom-right' });
					}
				}
				catch (error) {
					this.$toast.error(`Something went wrong! - ${error} !!!`, { position: 'bottom-right' });
					console.error("An error occurred:", error);
				}
			}
		},
		created() {
			this.$parent.$on('toggle-add-to-sheet', (isVisible) => {
				this.isButtonVisible = isVisible;
			});
		},
	}
	
</script>

<style scoped>
.checkbox {
  width: 20px;
  height: 20px;
  position: relative;
  margin: auto;
}
.checkbox > * {
  position: absolute;
}
.checkbox-visible {
  border: 1px solid gray;
  width: 16px;
  height: 16px;
  margin: 2px;
  background: #fff;
  border: 1px solid #dedede;
  border-radius: 3px;
}
.checkbox > input {
  z-index: 1;
  opacity: 0;
  left: 50%;
  top: 50%;
  transform: translatex(-50%) translatey(-50%);
  display: block;
  cursor: pointer;
  width: 100px;
  height: 35px;
}
.checkbox > input:checked + .checkbox-visible {
  background: #59cb59;
  border-color: #59cb59;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTQnIGhlaWdodD0nMTQnIHZpZXdCb3g9JzAgMCAxNCAxNCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48dGl0bGU+d2hpdGU8L3RpdGxlPjxwYXRoIGQ9J001LjM3OCAxMS4zN0wyIDcuNTlsMS40MzgtMS4yODZMNS4zNzQgOC40N2w1LjE4NS01Ljg0TDEyIDMuOTFsLTYuNjIyIDcuNDYnIGZpbGw9JyNmZmYnLz48L3N2Zz4=);
}
.checkbox > input:hover + .checkbox-visible {
  border-color: #cccccc;
}
.checkbox > input:hover:checked + .checkbox-visible {
  border-color: #0ca750;
}
.checkbox > input:focus + .checkbox-visible {
  border-color: #59cb59;
}
.redmine-table td {
	padding: 0!important;
}
.redmine-table td.text {
	padding: 0.75rem!important;
}
.redmine-table .ticket-link {
	text-decoration: underline;
}
.redmine-table .ticket-link:hover {
	text-decoration: none;
}
.redmine-table textarea,
.redmine-table input[type=text],
.redmine-table input[type=number],
.redmine-table input[type=text]:focus,
.redmine-table textarea:focus,
.redmine-table input[type=number]:focus,
.redmine-table input[type=text]:focus,
.redmine-table textarea:focus-visible,
.redmine-table input[type=text]:focus-visible {
	border: none;
	outline: none;
	width: 100%;
	padding: 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
</style>