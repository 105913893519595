<template>
	<div class="container-fluid">
		<div class="row">
			<main role="main" class="col-md-12 ml-sm-auto col-lg-12 pt-3 px-4">

				<UserInfo />
				<button class="btn btn-info mb-3" @click="toggleAddButton">Toggle Add to sheet</button>

				<div class="table-responsive redmine-sheet-table">
				<table class="table table-striped table-bordered">
					<thead>
					<tr>
						<th class="index">Sl.No</th>
						<th class="date">Date</th>
						<th class="code">Project Code</th>
						<th class="checkrow">Is Client</th>
						<th class="ticket-number">Ticket Number</th>
						<th class="checkrow">Is Billable</th>
						<th class="hours">Estimated Hours</th>
						<th class="hours">Actual Hours</th>
						<th class="hours">Billable Hours</th>
						<th>Comments</th>
						<th class="add-all-button">
							<button class="btn btn-info" @click="addRows">Create/Update Sheet</button>
						</th>
					</tr>
					</thead>
					
					<tbody>
						<RedmineRow v-bind:key="row.id" v-for="(row, index) in entries" v-bind:row="entryRow(row, index)"  @update-date="updateRowItems"/>
					</tbody>
				</table>
				</div>
			</main>
		</div>
	</div>
</template>
 
<script>
	import UserInfo from '@/components/UserInfo.vue';
	import RedmineRow from '@/components/RedmineRow.vue';
	import axios from 'axios'; // Import Axios
	import userdata from '@/userdata.json';
	import { GoogleSpreadsheet } from 'google-spreadsheet';
	import creds from '@/client_secret.json';
	export default {
		name: "RedmineSheet",
		components: {
			UserInfo,
			RedmineRow
		},
		props: ["redmineid"],
		async fetch() {
			await this.fetchTimeEntries()
		},
		data() {
			return {
				rows: [],
				loading: true,
				timeEntries: [],
				isAddToSheetVisible: false,
			}
		},
		computed: {
			entries() {
				return this.timeEntries || []
			}
		},
		methods:{
			async fetchTimeEntries() {
				try {
					const proxyUrl = process.env.VUE_APP_SERVER_URL
					const firstDay = this.$route.query.firstDay || '2023-09-01'
					const lastDay = this.$route.query.lastDay || '2023-09-30'
					const accessToken = JSON.parse(localStorage.getItem("formData"))?.accessToken || ''
					const response = await axios.get(`${proxyUrl}?redmineid=${this.$route.params.redmineid}&from=${firstDay}&to=${lastDay}&accessToken=${accessToken}`);
					if (response.error) {
						console.log('Please check the access token you have provided.')
					}
					this.timeEntries = this.cleanRows(response?.data?.time_entries) || [];
				} catch (error) {
					console.error(error);
				}
			},
			entryRow(row, indexNumber) {
				return { index: indexNumber, ...row };
			},
			billableHours(hours) {
				if (this.$route.params.redmineid == 466 && hours == 8.5) {
					return 8
				}
				return hours
			},
			cleanRows(timeEntries) {
				let timeEntriesArray = timeEntries.map(item => {
					const fullDate = new Date(item.spent_on);
					const date = this.dateFormat(item.spent_on, 'dd/MM/yyyy');
					const day = this.getDayOfWeek(fullDate);
					return {
						date: date,
						projectCode: item.project.name,
						isClient: "",
						ticketNumber: item.issue.id,
						isBillable: "",
						hours: item.hours,
						actualHours: item.hours,
						billableHours: this.billableHours(item.hours),
						comments: item.comments,
						day,

					}
				});
				timeEntriesArray = this.sortDate(timeEntriesArray)
				let lastTimeEntry = timeEntriesArray[timeEntriesArray.length - 1];
				let timeEntriesFull = this.addMissingDays(timeEntriesArray, timeEntriesArray[0].date, lastTimeEntry.date)
				timeEntriesFull = this.sortDate(timeEntriesFull)

				return timeEntriesFull
			},
			sortDate(timeEntriesArray) {
				timeEntriesArray.sort(function(a, b){
					var aa = a.date.split('/').reverse().join(),
						bb = b.date.split('/').reverse().join();
					return aa < bb ? -1 : (aa > bb ? 1 : 0);
				});
				return timeEntriesArray
			},
			getDayOfWeek(date) {
				const dayOfWeek = new Date(date).getDay();    
				return isNaN(dayOfWeek) ? null : 
					['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayOfWeek];
			},
			dateFormat(inputDate, format) {
				//parse the input date
				const date = new Date(inputDate);

				//extract the parts of the date
				const day = date.getDate();
				const month = date.getMonth() + 1;
				const year = date.getFullYear();

				//replace the month
				format = format.replace("MM", month.toString().padStart(2,"0"));

				//replace the year
				if (format.indexOf("yyyy") > -1) {
					format = format.replace("yyyy", year.toString());
				} else if (format.indexOf("yy") > -1) {
					format = format.replace("yy", year.toString().substr(2,2));
				}

				//replace the day
				format = format.replace("dd", day.toString().padStart(2,"0"));

				return format;
			},
			addMissingDays(data, entryStartDate, entryEndDate) {
				// Parse start and end dates into Date objects
				const startDateParts = entryStartDate.split('/');
				const endDateParts = entryEndDate.split('/');
				
				const startDate = new Date(`${startDateParts[2]}-${startDateParts[1]}-${startDateParts[0]}`);
				const endDate = new Date(`${endDateParts[2]}-${endDateParts[1]}-${endDateParts[0]}`);

				const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds

				let currentDate = startDate;
				const newData = [];

				while (currentDate <= endDate) {
					const dateString = currentDate.toLocaleDateString('en-GB'); // Format date as "dd/mm/yyyy"
					
					// Check if the date is present in the original data
					const dateExists = data.some(item => item.date === dateString);
					if (!dateExists) {
					// If the date is missing, add an object with empty values
					newData.push({
						date: dateString,
						projectCode: "",
						isClient: "",
						ticketNumber: "",
						isBillable: "",
						hours: "",
						actualHours: "",
						billableHours: "",
						comments: "",
						day: currentDate.toLocaleDateString('en-US', { weekday: 'long' }),
					});
					}

					// Move to the next day
					currentDate = new Date(currentDate.getTime() + oneDay);
				}
				// Combine the original data with the new data
				const combinedData = [...data, ...newData];
				return combinedData;
			},
			findNameById(array, id) {
				const result = array.find(item => item.id === id);
				return result ? result.name : null;
			},
			async addRows() {
				// Select all row elements
				const rowElements = this.$el.querySelectorAll('.redmine-table');

				// Loop through the selected row elements and retrieve their values
				const rows = [];
				rowElements.forEach(rowElement => {
					const elements = rowElement.querySelectorAll('input[name^="rowitem"], textarea[name^="rowitem"]');
					const values = [];
					elements.forEach(element => {
						values.push(element.value);
					});
					rows.push(values);
				});

				// Extract unique dates from the array
				const uniqueDates = [...new Set(rows.map(item => item[0]))];
				// Generate an array of all dates in the desired range
				const allDates = this.generateAllDatesInARange(uniqueDates);
				// Iterate through all dates and add missing ones to the array
				allDates.forEach(date => {
					const found = rows.some(item => item[0] === date);
					if (!found) {
						// Add a placeholder for missing date
						rows.push([date, "", "", "", "", "", "", "", ""]);
					}
				});

				rows.sort((a, b) => {
					const [dayA, monthA, yearA] = a[0].split("/");
					const [dayB, monthB, yearB] = b[0].split("/");
					const dateA = new Date(yearA, monthA - 1, dayA);
					const dateB = new Date(yearB, monthB - 1, dayB);
					return dateA - dateB;
				});

				const userId = this.$route.params.redmineid
				const userName = this.findNameById(userdata.users, userId)

				// Parse the date string into a Date object
				const rowDate = this.$route.query.lastDay
				// Split the date string into day, month, and year components
				const [year, month, day] = rowDate.split('-').map(Number);
				// Create a Date object with the extracted components (subtract 1 from month as it's 0-based)
				const date = new Date(year, month - 1, day);

				// Get the month as a number (0-based index)
				const monthNumber = date.getMonth();
				const yearNumber = date.getFullYear();

				// Create an array of month names
				const monthNames = [
					"January", "February", "March", "April", "May", "June",
					"July", "August", "September", "October", "November", "December"
				];

				// Get the month name
				const monthName = monthNames[monthNumber];

				// Filtering empty value rows if there is an entry with value for same date.
				const filteredRows = rows.filter((row, index, self) => {
				const sameDateRow = self.find((r, i) =>
					i !== index &&
					r[0] === row[0] &&
					r.some((value, valueIndex) => valueIndex !== 0 && value !== '')
				);
				return !sameDateRow || row.some((value, valueIndex) => valueIndex !== 0 && value !== '');
				});

				try {
					const doc = new GoogleSpreadsheet(userdata.googleSheetId);
					await doc.useServiceAccountAuth(creds);
					await doc.loadInfo();
					const sheetName = userName + ' - ' + monthName + ' ' + yearNumber
					const sheet = doc.sheetsByTitle[sheetName];
					if (!sheet) {
						const dummySheet = doc.sheetsByTitle['DoNotEditOrTouch'];
						let duplicateSheet = await dummySheet.duplicate()
						await duplicateSheet.updateProperties({title: sheetName})
						await duplicateSheet.addRows(filteredRows); // Add all rows at once
						this.$toast.success(`Sheet Created and added to ${sheetName} !!!`, { position: 'bottom-right' });
					}
					else {
						await sheet.addRows(filteredRows); // Add all rows at once
						this.$toast.success(`Added to ${sheetName} !!!`, { position: 'bottom-right' });
					}
				}
				catch (error) {
					this.$toast.error(`Something went wrong! - ${error} !!!`, { position: 'bottom-right' });
					console.error("An error occurred:", error);
				}
			},

			// Function to generate all dates in a range
			generateAllDatesInARange(existingDates) {
				const startDate = new Date(Math.min(...existingDates.map(date => {
					const [day, month, year] = date.split('/').map(Number);
					return new Date(year, month - 1, day);
				})));

				const endDate = new Date(Math.max(...existingDates.map(date => {
					const [day, month, year] = date.split('/').map(Number);
					return new Date(year, month - 1, day);
				})));

				const allDates = [];
				for (let currentDate = startDate; currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {
					allDates.push(this.dateFormat(currentDate, 'dd/MM/yyyy'));
				}

				return allDates;
			},
			updateRowItems(updatedRowItems) {
				const index = this.timeEntries.findIndex((rowItems, index) => index === updatedRowItems.index);
				if (index !== -1) {
					this.$set(this.timeEntries, index, updatedRowItems);
				}
			},
			toggleAddButton() {
				this.isAddToSheetVisible = !this.isAddToSheetVisible;
				this.$emit('toggle-add-to-sheet', this.isAddToSheetVisible);
			},
		},
		created () {
			return this.fetchTimeEntries()
		},
		beforeRouteUpdate(to, from, next) {
			next();
		}
	}
</script>

<style scoped>
.redmine-sheet-table table th.index {
	width: 30px;
}
.redmine-sheet-table table th.date {
	width: 120px;
}
.redmine-sheet-table table th.code {
	width: 200px;
}
 .redmine-sheet-table table th.hours {
	width: 100px;
 }

 .redmine-sheet-table table th.checkrow {
	width: 50px;
 }

 .redmine-sheet-table table th.ticket-number {
	width: 100px;
 }
</style>