<template>
    <div class="container">
        <div class="row">
            <div class="col-12 mt-3 mb-3 pb-3 todo-container">
                <h1>Todo List</h1>
                <form @submit.prevent="submitForm">
                    <div class="row">
                        <div class="col-3 mb-3">
                            <label for="date" class="form-label">Date</label>
                            <input type="date" class="form-control" id="date" v-model="date">
                        </div>
                        <div class="col-2 mb-3">
                            <label for="time" class="form-label">Hours</label>
                            <input type="number" class="form-control" id="time" v-model="time" step="0.5" min="0.5" max="9">
                        </div>
                        <div class="col-7 mb-3">
                            <label for="project" class="form-label">Project</label>
                            <select class="form-control ml-1 mr-1 mb-2" name="project" id="project" v-model="selectedProject">
                                <option v-for="(project, index) in projects" :key="index" :value="project.name">
                                    {{ project.name }}
                                </option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="notes" class="form-label">Notes</label>
                        <textarea class="form-control" id="notes" rows="3" v-model="notes" required></textarea>
                    </div>
                    <button type="submit" class="btn btn-primary">Submit</button>
                </form>
            </div>

            <div class="col-12" v-if="todos.length">
                <div class="col-12 action-buttons text-right mb-4">
                    <div class="row action-button-row">
                        <button class="btn btn-primary mr-2" @click="switchSummary">Show Summary</button>
                        <button class="btn btn-primary" @click="switchView">Switch View</button>
                    </div>
                    <!-- ... -->
                </div>

                <div class="col-12 mb-5" v-if="showSummary == true">
                    <div class="row">
                      <h5>Summary</h5>
                      <table class="table">
                        <tbody>
                          <tr>
                            <td>Total hours</td>
                            <td>{{ totalHoursSummary }}</td>
                          </tr>
                          <tr>
                            <td>Total tickets</td>
                            <td>{{ totalTicketsSummary }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                <div id="accordion todo-list">
                    <div v-for="(groupedTodos, groupedBy, index) in (showGroupProject ? groupedTodosByProject : groupedTodosByDate)" :key="groupedBy" class="card mb-1" :class="{'today-item' : groupedBy ==  new Date().toISOString().slice(0,10)}">
                        <div class="card-header" :id="'heading' + index">
                            <h5 class="mb-0 d-flex">
                                <button class="btn btn-link text-body main-button" data-toggle="collapse" :data-target="'#collapse-project' + index" aria-expanded="true" :aria-controls="'collapse' + index">
                                    {{ groupedBy }}
                                </button>
                                <span class="badge badge-info ml-2 align-self-center" v-if="showGroupProject && getProjectDetails(groupedTodos[0], 'identifier')">
                                    <a :href="`https://projects.zyxware.net/projects/${getProjectDetails(groupedTodos[0], 'identifier')}/issues`" class="text-white" target="_blank">View Redmine</a>
                                </span>
                                <span class="badge badge-primary ml-2 align-self-center" v-if="groupedBy ==  new Date().toISOString().slice(0,10)">Today</span>
                                <span class="badge badge-info ml-auto align-self-center">{{ totalHours(groupedTodos) }} hour</span>
                            </h5>
                        </div>
                        <div :id="'collapse-project' + index" class="collapse show" :aria-labelledby="'heading' + index" data-parent="#accordion">
                            <div class="card-body">
                                <div v-for="todo in groupedTodos" :key="todo.id" class="todo-item border" :class="{ strikethrough: todo.completed }">
                                    <div class="d-flex align-items-center">
                                        <input type="checkbox" v-model="todo.completed" class="mr-2">
                                        <h5 class="mb-0 mr-2">{{ showGroupProject ? todo.date : todo.project }}</h5>
                                        <div class="ml-auto todo-action-tools">
                                            <button title="Log to Redmine" class="btn btn-secondary mr-1 ml-1" @click="redmineAdd(todo)"><i class="material-icons">sync_alt</i></button>
                                            <button title="Copy" class="btn btn-info mr-1 ml-1" @click="copyToClipboard(todo.notes)"><i class="material-icons">content_copy</i></button>
                                            <button title="Edit" class="btn btn-primary mr-1 ml-1" data-toggle="modal" :data-target="'#editModal' + todo.id"><i class="material-icons">edit</i></button>
                                            <button title="Delete" class="btn btn-danger mr-1 ml-1" @click="$toast.info('Double click to delete !!!', { position: 'bottom-right' })" @dblclick="todos.splice(todos.indexOf(todo), 1), $toast.error('Delete todo !!!', { position: 'bottom-right' })"><i class="material-icons">delete</i></button>
                                        </div>

                                        <!-- Edit Modal -->
                                        <div class="modal fade" :id="'editModal' + todo.id" tabindex="-1" role="dialog" aria-labelledby="editModalLabel" aria-hidden="true">
                                            <div class="modal-dialog" role="document">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="editModalLabel">Edit Todo</h5>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <form @submit.prevent="editTodo(todo)">
                                                            <div class="row">

                                                                <div class="col-12">
                                                                    <select class="form-control ml-1 mr-1 mb-2" name="project" id="project" v-model="todo.project" @change="closeModalBackdrop" v-show="showEditProjectSelect">
                                                                        <option v-for="(project, index) in projects" :key="index" :value="project.name">
                                                                            {{ project.name }}
                                                                        </option>
                                                                        <option value="Other">Other</option>
                                                                    </select>
                                                                </div>

                                                                <div class="col-6 mb-3" v-show="!showEditProjectSelect">
                                                                    <label for="time" class="form-label">Hours</label>
                                                                    <input type="number" class="form-control" id="time" v-model="todo.time" step="0.5" min="0.5" max="9">
                                                                </div>
                                                                <div class="col-6 mb-3" v-show="!showEditProjectSelect">
                                                                    <label for="ticket" class="form-label">Redmine Ticket</label>
                                                                    <input type="number" class="form-control" id="ticket" v-model="todo.ticket" step="1">
                                                                </div>
                                                            </div>
                                                            <div class="mb-3" v-show="!showEditProjectSelect">
                                                                <label for="notes" class="form-label">Notes</label>
                                                                <textarea class="form-control" id="notes" rows="3" v-model="todo.notes" required></textarea>
                                                            </div>
                                                            <button type="submit" class="btn btn-primary" data-dismiss="modal" aria-label="Close">Submit</button>
                                                            <button class="btn btn-primary ml-1" @click.prevent="showEditProjectSelect = !showEditProjectSelect" v-show="!showEditProjectSelect">Change Project</button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="full-width todo-basic-display">
                                        <label class="mr-2 badge badge-primary">{{ todo.time }} hour</label>
                                        <label v-if="todo.ticket" class="mr-2 badge badge-secondary">
                                            <a target="_blank" :href="`https://projects.zyxware.net/issues/${todo.ticket}`" class="text-white">{{ todo.ticket }}</a>
                                        </label>
                                    </div>
                                    <div class="full-width todo-notes-display">{{ todo.notes }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'; // Import Axios
import {v4 as uuidv4} from 'uuid'; // Import UUID

export default {
    data() {
        return {
            date: new Date().toISOString().split('T')[0],
            time: 0,
            notes: '',
            projects: [],
            selectedProject: 'OB-Organisation Bench',
            showEditProjectSelect: false,
            todos: JSON.parse(localStorage.getItem('todos')) || [],
            showGroupProject: JSON.parse(localStorage.getItem('showGroupProject')) || false,
            showSummary: JSON.parse(localStorage.getItem('showSummary')) || false,
        }
    },
    computed: {
        groupedTodosByProject() {
            return this.todos.reduce((grouped, todo) => {
                (grouped[todo.project] = grouped[todo.project] || []).push(todo);
                return grouped;
            }, {});
        },
        groupedTodosByDate() {
            // Group the todos by date
            let grouped = this.groupBy(this.todos, 'date');
            // Sort the grouped todos by date
            let sortedKeys = Object.keys(grouped).sort((a, b) => new Date(b) - new Date(a));
            // Create a new object with the sorted keys
            let sortedGrouped = {};
            for (let key of sortedKeys) {
                sortedGrouped[key] = grouped[key];
            }
            return sortedGrouped;
        },
        totalHoursSummary() {
            return this.totalHours(this.todos);
        },
        totalTicketsSummary() {
            return this.todos.length;
        }
    },
    methods: {
		async fetchProjects() {
			try {
                const savedProjects = localStorage.getItem('projects');
                const savedTime = localStorage.getItem('projectsTime');

                const time = 3 * 60 * 60 * 1000; // in milliseconds
                const isDataStale = !savedTime || (Date.now() - savedTime > time);

                if (savedProjects && !isDataStale) {
                    this.projects = JSON.parse(savedProjects);
                    return;
                }

                const localData =  JSON.parse(localStorage.getItem("formData"));
                const accessToken = localData?.accessToken ? '?accessToken=' + localData?.accessToken : '';
				const proxyUrl =  process.env.VUE_APP_SERVER_URL
				const response = await axios.get(`${proxyUrl}/redmine-projects${accessToken}`);
				if (response.error) {
					console.log('Please check the access token you have provided.')
				}
				this.projects = response?.data?.projects || [];

                // Save the data to localStorage
                localStorage.setItem('projects', JSON.stringify(this.projects));
                localStorage.setItem('projectsTime', Date.now());

			} catch (error) {
				console.error(error);
			}
		},
        submitForm() {
            // Create a new todo object
            const newTodo = {
                id: uuidv4(), // Generate a random ID
                date: this.date,
                time: parseFloat(this.time),
                project: this.selectedProject,
                notes: this.notes
            };

            // Add the new todo to the todos array
            this.todos.push(newTodo);

            // Save the todos array to local storage
            localStorage.setItem('todos', JSON.stringify(this.todos));

            // Reset the form
            this.time = 0;
            this.notes = '';
            this.$toast.success(`Added Todo !!!`, { position: 'bottom-right' });

        },
        editTodo(todo) {
            // Find the index of the todo in the todos array
            const index = this.todos.findIndex(t => t.id === todo.id);

            // Update the todo in the todos array
            this.$set(this.todos, index, todo);

            // Update the todos in localStorage
            localStorage.setItem('todos', JSON.stringify(this.todos));
            this.$toast.success(`Updated Todo !!!`, { position: 'bottom-right' });

            // Remove the modal backdrop
            this.closeModalBackdrop();
        },
        closeModalBackdrop() {
            if (!this.showGroupProject) {
                return;
            }
            // Remove the modal backdrop
            const backdrop = document.querySelector('.modal-backdrop');
            if (backdrop) {
                backdrop.parentNode.removeChild(backdrop);
            }
            // Remove .modal-open class from body
            document.body.classList.remove('modal-open');
            this.showEditProjectSelect = false;
        },
        totalHours(todos) {
            return todos.reduce((total, todo) => parseFloat(total) + parseFloat(todo.time), 0);
        },
        switchView() {
            this.showGroupProject = !this.showGroupProject;
            const view = this.showGroupProject ? 'Project' : 'Date';
            localStorage.setItem('showGroupProject', this.showGroupProject)
            this.$toast.info(`Showing  ${view} wise view!!!`, { position: 'bottom-right' });
        },
        switchSummary() {
            this.showSummary = !this.showSummary;
            const summary = this.showSummary ? 'turned on' : 'turned off';
            localStorage.setItem('showSummary', this.showSummary)
            this.$toast.info(`Sumary view ${summary}!!!`, { position: 'bottom-right' });
        },
        groupBy(array, key) {
            return array.reduce((result, currentItem) => {
                (result[currentItem[key]] = result[currentItem[key]] || []).push(currentItem);
                return result;
            }, {});
        },
        copyToClipboard(text) {
            if (navigator.clipboard) {
                navigator.clipboard.writeText(text).then(() => {
                    console.log('Copying to clipboard was successful!');
                    this.$toast.success(`Copied to clipboard!`, { position: 'bottom-right' });
                }, err => {
                    console.error('Could not copy text: ', err);
                    this.$toast.error(`Copying to clipboard failed!`, { position: 'bottom-right' });
                });
            }
            else {
                let textarea = document.createElement('textarea');
                try {
                    textarea.textContent = text;
                    document.body.appendChild(textarea);
                    textarea.select();
                    document.execCommand('copy');
                    this.$toast.success(`Copied to clipboard!`, { position: 'bottom-right' });
                } catch (err) {
                    console.error('Fallback: Could not copy text: ', err);
                    this.$toast.error(`Copying to clipboard failed!`, { position: 'bottom-right' });
                } finally {
                    document.body.removeChild(textarea);
                }
            }
        },
        redmineAdd(todo) {
            if (todo.ticket) {
                if (todo.completed) {
                    const identifier = this.getProjectDetails(todo, 'identifier')
                    if (identifier) {
                        return window.open(`https://projects.zyxware.net/projects/${identifier}/time_entries?sort=spent_on:desc&f[]=spent_on&op[spent_on]==&v[spent_on][]=${todo.date}&f[]=&c[]=spent_on&c[]=user&c[]=activity&c[]=issue&c[]=comments&c[]=hours&group_by=&t[]=hours&t[]=`, '_blank')
                    }
                }
                return window.open(`https://projects.zyxware.net/issues/${todo.ticket}/time_entries/new?time_entry[hours]=${todo.time}&time_entry[comments]=${todo.notes}`, '_blank')
            }
            else {
                this.$toast.info('Coming Soon: Logging to redmine!!!', { position: 'bottom-right' })
            }
        },
        getProjectDetails(todo, key) {
            const project = this.projects.find(p => p.name === todo.project);
            return project ? project[key] : null;
        }
    },
	created () {
		return this.fetchProjects()
	},
	beforeRouteUpdate(to, from, next) {
		next();
	},
    watch: {
        todos: {
            handler() {
                localStorage.setItem('todos', JSON.stringify(this.todos));
            },
            deep: true
        }
    }
}
</script>

<style>
.todo-container {
    border-bottom: 1px solid #ccc;
}
.strikethrough {
    text-decoration: line-through;
}
.todo-item {
    padding: 10px;
    margin-bottom: 10px;
    margin-left: 30px;
    padding-left: 30px;
}
.action-button-row {
    justify-content: flex-end;
}

.todo-action-tools button {
    padding: 3px 7px;
}
.todo-action-tools button i {
    font-size: 14px;
}
.full-width {
    width: 100%;
    display: block;
    clear: both;
}
.today-item {
    box-shadow: 0 0 10px var(--info);
}
</style>