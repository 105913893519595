<template>
	<div class="home">
		<SpreadSheet />
	</div>
</template>

<script>
// @ is an alias to /src
import SpreadSheet from '@/components/SpreadSheet.vue'

export default {
	name: 'HomePage',
	components: {
		SpreadSheet
	}
}
</script>
