<template>
  <div id="app">
    <div id="nav">
      <nav class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 justify-content-start">
        <router-link class="navbar-brand col-sm-3 col-md-2 mr-0" to="/">Zyxware Helper</router-link>

        <!-- New Todo Menu -->
        <ul class="navbar-nav px-3">
          <li class="nav-item text-nowrap d-flex">
            <router-link class="nav-link" to="/todo">Todo <sup><span class="badge badge-light">New</span></sup></router-link>
          </li>
        </ul>

        <!-- <input class="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search"> -->
        <ul class="navbar-nav px-3 d-flex flex-row ml-auto">
          <li class="nav-item text-nowrap d-flex">
            <span v-for="(jiraUser, index) in userdata.jiraUsers" :key="index" class="mr-3">
              <router-link class="nav-link" :to="`/jira/${jiraUser.id}`">Victoria Jira ({{jiraUser.name}})</router-link>
            </span>
          </li>
          <li class="nav-item text-nowrap d-flex pl-3 theme-switcher">
            <select v-model="selectedTheme" class="form-control">
              <option v-for="(theme, key) in validThemes" :value="key" :key="key">{{ theme }}</option>
              <!-- Add more options as needed -->
            </select>
          </li>
        </ul>
      </nav>
    </div>
    <div id="main">
      <router-view :key="routeFullPath"/>
    </div>
    <footer id="footer" class="footer mt-5">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <p class="text-center mt-3">
              Made with &#10084; | <a href="https://www.odostore.in/" target="_blank"> Adharsh M</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
	import userdata from '@/userdata.json';

	export default {
    data () {
      return {
        userdata,
        selectedTheme: localStorage.getItem('theme') || 'sketchy',
        validThemes: {
          'cerulean': 'Cerulean',
          'cosmo': 'Cosmo',
          'cyborg': 'Cyborg',
          'darkly': 'Darkly',
          'flatly': 'Flatly',
          'journal': 'Journal',
          'litera': 'Litera',
          'lumen': 'Lumen',
          'lux': 'Lux',
          'materia': 'Materia',
          'minty': 'Minty',
          'pulse': 'Pulse',
          'sandstone': 'Sandstone',
          'simplex': 'Simplex',
          'sketchy': 'Sketchy',
          'slate': 'Slate',
          'solar': 'Solar',
          'spacelab': 'Spacelab',
          'superhero': 'Superhero',
          'united': 'United',
          'yeti': 'Yeti'
        }
      }
    },
    computed: {
			routeFullPath() {
        return this.$route.fullPath
      }
    },
    methods: {
      getTheme() {
        // Get the theme from localStorage
        var theme = localStorage.getItem('theme');
        if (theme) {
          var linkTag = document.querySelector('link[href*="bootswatch.com"]');
          linkTag.href = 'https://bootswatch.com/4/' + theme + '/bootstrap.css';
        }
      }
    },
    watch: {
      selectedTheme(newTheme) {
        var linkTag = document.querySelector('link[href*="bootswatch.com"]');
        linkTag.href = 'https://bootswatch.com/4/' + newTheme + '/bootstrap.css';

        // Save the theme to localStorage
        localStorage.setItem('theme', newTheme);
      },
    },
    created() {
      this.getTheme();
    },

  }
</script>
>
<style>
.container {
  max-width: 960px;
}

.border-top { border-top: 1px solid #e5e5e5; }
.border-bottom { border-bottom: 1px solid #e5e5e5; }
.border-top-gray { border-top-color: #adb5bd; }

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }

.lh-condensed { line-height: 1.25; }

.theme-switcher {
  align-items: center;
}
.theme-switcher select.form-control {
  padding: 5px;
}
.footer {
  border-top: 1px solid #e5e5e5;
}
</style>
