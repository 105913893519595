<template>
	<div class="container-fluid">
		<div class="row">
			<main role="main" class="col-md-12 ml-sm-auto col-lg-12 pt-3 px-4">


				<UserInfo/>

				<div class="well">
					<ul>
						<li>Visit <a href="https://projects.zyxware.net/my/account" target="_blank">Redmine</a> to obtain your access token.</li>
						<li>Copy the access token to the field above and save it.</li>
						<li>Please use your own access token to prevent any missing time entries.</li>
						<li>Utilize the filter in the top-right corner to retrieve time entries from Redmine.</li>
						<li><b>Note:</b> The Google Sheet we are using is not Zyxware's Redmine Billability sheet.</li>
						<li>This serves as a template for Zyxware's billability sheet. Once all time entries are entered, copy the data to Zyxware RBS.</li>
						<li><b>Do not delete</b> any sheets from the Google Sheet.</li>
						<li>Ensure that all time entries are accurate, and the total time matches your monthly work.</li>
						<li>For each month and each user, a separate sheet will be created with the user's name and the month. Example: <code>Adharsh M - March 2023</code>.</li>
						<li>Clicking "View Google Sheet" may open a different sheet. Please find your sheet using the naming convention mentioned above.</li>
						<li>In the Redmine listing sheet, you'll find an "Add to sheet" button that allows you to add time entries to the Google sheet.</li>
						<li>Some table rows may be empty, which could represent holidays or leaves.</li>
						<li>Rows with a red background correspond to Saturday and Sunday, while other empty rows might indicate leaves or non-tracked days.</li>
						<li>The table below contains data from the Google sheet, not the Redmine time entry list. Use the filter to begin adding time entries to the Google sheet.</li>
						<li>This application is not developed by Zyxware; it simply utilizes Zyxware API with access tokens from users who have access to Zyxware Redmine.</li>
						<li>Please avoid spam clicking the "Add to sheet" button since the entire application operates via AJAX and may require some time to process entries.</li>
						<li>This application is still a work in progress, so there may be occasional issues. If you encounter any problems, please report them to <code>adharsh.m@zyxware.com</code>.</li>
					</ul>
				</div>
				<h5>Google Sheet<span class="badge badge-danger badge-pill ml-2">WORK IN PROGRESS</span></h5>
				<div class="table-responsive">
				<table class="table table-striped table-bordered">
					<thead>
					<tr>
						<th>Date</th>
						<th>Project Code</th>
						<th>Is Client</th>
						<th>Ticket Number</th>
						<th>Is Billable</th>
						<th>Estimated Hours</th>
						<th>Actual Hours</th>
						<th>Billable Hours</th>
						<th>Comments</th>
					</tr>
					</thead>
					
					<tbody>
						<SpreadSheetRow v-bind:key="row.id" v-for="row in rows" v-bind:row="row" />
					</tbody>
				</table>
				</div>
			</main>
		</div>
	</div>
</template>
 
<script>
import UserInfo from '@/components/UserInfo.vue';
import userdata from '@/userdata.json';
import SpreadSheetRow from '@/components/SpreadSheetRow.vue';
import { GoogleSpreadsheet } from 'google-spreadsheet';
import creds from '@/client_secret.json';

export default {
	name: "SpreadSheet",
	components: {
		UserInfo,
		SpreadSheetRow
	},
	props: ["sheet"],
	data() {
		return {
			rows: [],
			loading: true,
		}
	},
	methods:{
		async accessSpreadSheet() {
			const doc = new GoogleSpreadsheet(userdata.googleSheetId);
			await doc.useServiceAccountAuth(creds);
			await doc.loadInfo();
			const sheet = doc.sheetsByIndex[0];
			const  rows = await sheet.getRows({
				offset: 0
			})
			this.rows = rows;
		},
	},
	created() {
		this.accessSpreadSheet();
	}
}
</script>

<style scoped>
h5 .badge{
	font-size: 9px;
}
</style>
