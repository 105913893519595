<template>
	<div class="redmine">
		<RedmineSheet :redmineid="redmineId"/>
	</div>
</template>

<script>
// @ is an alias to /src
import RedmineSheet from '@/components/RedmineSheet.vue'

export default {
	name: 'RedminePage',
	components: {
		RedmineSheet
	},
	computed:{
		redmineId() {
			return this.$route.params.redmineid
		}
	}
}
</script>
