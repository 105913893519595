<template>
	<tr>
		<td v-bind:key="index" v-for="(rowData, index) in rowItems">{{rowData}}</td>
	</tr>
</template>

<script>
	export default {
		name: "SpreadSheetRow",
		props: ["row"],
		computed: {
			rowItems() {
				const items = this.row._rawData
				return this.arrayLimit(items,  9)
			}
		},
		methods: {
			arrayLimit(array, minLength) {
				const filledArray = [...array].splice(0, 9)
				while (filledArray.length < minLength) {
					filledArray.push('')
				}
				return filledArray
			}
		}
	}
	
</script>

<style scoped>

</style>