import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/HomePage.vue'
import RedminePage from '../views/RedminePage.vue'
import JiraEntries from '../views/JiraEntries.vue'
import TodoEntries from '../views/TodoEntries.vue'

Vue.use(VueRouter)
  
  const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/jira',
    name: 'Jira',
    component: JiraEntries
  },
  {
    path: '/todo',
    name: 'Todo',
    component: TodoEntries
  },
  {
    path: '/jira/:jiraid',
    name: 'JiraEntries',
    component: JiraEntries
  },
  {
    path: '/:redmineid',
    name: 'RedminePage',
    component: RedminePage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router
