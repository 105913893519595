<template>
	<div>
		<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 ">
			<h2>Zyxware Google Sheet</h2>
			<div class="btn-toolbar mb-md-0">

				<div v-if="!showForm" class="d-flex align-self-center ml-2 mr-2">
					<p class="mb-0">
						Redmine Access Token:<span type="button" class="badge badge-danger resetbutton ml-2" @click="resetForm">Reset</span>
						<br/>
						<code class="blur">{{ localStorageData.accessToken }}</code>
					</p>
				</div>
				<div v-else>
					<form  @submit.prevent="saveData" class="form-inline mr-3 mb-2">
						<div class="form-group ">
							<label for="accessToken" class="sr-only">Redmine Access Token:</label>
							<input type="text" id="accessToken" class="form-control" v-model="accessToken" placeholder="Redmine Access Token" required>
						</div>
						<div class="form-group">
							<button class="btn btn-primary ml-1" type="submit">Save</button>
						</div>
					</form>
				</div>

				<a href="https://docs.google.com/spreadsheets/d/118ghbXixyPY3bowBLjzvO3XTIrWaUCeYXF6LindsfoY/edit?usp=sharing" 
					class="btn btn-primary ml-1 mr-1 mb-2"
					target="_blank">
				View Google Sheet
				</a>
				<form class="form-inline">
					<select class="form-control ml-1 mr-1 mb-2" name="month" v-model="selectedMonth">
						<option v-for="(month, index) in getFirstAndLastDaysOfLast6Months" :key="index" :value="month.lastDay">
							{{ month.month }} {{ month.year }}
						</option>
					</select>
					<select class="form-control ml-1 mr-1 mb-2" name="redmineid" v-model="redmineid" >
						<option v-for="(user, index) in userData.users" :key="index" :value="user.id">{{ user.name }}</option>
					</select>
					<button class="ml-1 mr-1 mb-2 btn btn-secondary" @click="changeRoute">View</button>
				</form>

			</div>
		</div>


	</div>
</template>

<script>
	import userdata from '@/userdata.json';
	export default {
		name: "UserInfo",
		props: [],
		data() {

			// Initialize selectedMonth to the last day of the current month
			const today = new Date();
			const year = today.getFullYear();
			const month = today.getMonth() + 1; // Adding 1 since months are 0-based
			const lastDayOfMonth = new Date(year, month, 0).getDate(); // Get the last day of the current month
			const formattedMonth = `${year}-${String(month).padStart(2, '0')}-${lastDayOfMonth}`;
			return {
				accessToken: "",
				userId: "",
				showForm: true,
				redmineid: this.$route.params.redmineid || '466',
				selectedMonth: this.$route.query.lastDay || formattedMonth,
			}
		},
		computed: {
			userData() {
				return userdata
			},
			localStorageData() {
				const localData =  JSON.parse(localStorage.getItem("formData"));
				return {
					accessToken: localData?.accessToken,
					spreadsheetId: localData?.spreadsheetId
				}
			},
			getFirstAndLastDaysOfLast6Months() {
				const today = new Date();
				const firstAndLastDays = [];

				// Iterate through the last 6 months, starting from the current month
				for (let i = 0; i < 6; i++) {
					// Get the year and month for the current iteration
					const year = today.getFullYear();
					const month = today.getMonth();

					// Get the month name and format it
					const monthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(today);


					// Calculate the last day of the current month
					const lastDay = new Date(year, month + 1, 0);

					// Format the first and last days as 'YYYY-MM-DD'
					const formattedFirstDay = `${year}-${String(month + 1).padStart(2, '0')}-01`;
					const formattedLastDay = `${year}-${String(month + 1).padStart(2, '0')}-${String(lastDay.getDate()).padStart(2, '0')}`;

					// Add the first and last days to the array
					firstAndLastDays.push({
						firstDay: formattedFirstDay,
						lastDay: formattedLastDay,
						month: monthName,
						year
					});

					// Move back one month for the next iteration
					today.setMonth(today.getMonth() - 1);
				}
				return firstAndLastDays; // Reverse the array to get days in chronological order
			}

		},
		methods: {
			changeRoute () {
				// Access the selected values here
				const selectedMonth = this.selectedMonth;
				const selectedRedmineId = this.redmineid;
				// Split the original date string into year, month, and day
				const [year, month] = selectedMonth.split('-');

				// Create a new date string with the first day of the same month
				const firstDay = `${year}-${month}-01`;
				this.$router.push({
					path: `/${selectedRedmineId}`, // Set redmineid as the URL path
					query: {
						firstDay, // Set month as a query parameter
						lastDay: selectedMonth, // Set month as a query parameter
					}
				})
			},
			saveData() {
				// Save form data to local storage
				localStorage.setItem("formData", JSON.stringify({
					accessToken: this.accessToken,
				}));
				this.showForm = false
			},
			resetForm() {
				// Clear local storage and reset form data
				localStorage.removeItem("formData");
				this.showForm = true
			},
		},
		mounted () {
			if (JSON.parse(localStorage.getItem("formData"))) {
				this.accessToken = ''
				this.showForm = false
			}
		}
	}
	
</script>

<style scoped>
	.resetbutton {
		cursor: pointer;
	}
	.blur {
		color: #e83e8d00;
		text-shadow: 0 0 5px rgba(232, 62, 141, 0.8);
	}
	.blur:hover {
		color: rgb(232, 62, 141);;
		text-shadow: none;
	}
</style>
