<template>
	<div class="container-fluid">
		<div class="row">
			<main role="main" class="col-md-12 ml-sm-auto col-lg-12 pt-3 px-4">
				<div class="jira">
					<h2>Zyxware Jira Sheet <span class="badge badge-light">(Last 4-5 weeks)</span></h2>
					<div class="table-responsive">
						<table class="table table-striped table-bordered jira-issues" v-if="jiraIssues">
							<thead>
							<tr>
								<th>Issue Type<br />
									<span class="badge badge-primary" @click="copyColumn(0)">Copy</span>
								</th>
								<th>Issue Key<br/>
									<span class="badge badge-primary" @click="copyColumn(1)">Copy</span>
								</th>
								<th>Summary<br/>
									<span class="badge badge-primary" @click="copyColumn(2)">Copy</span>
								</th>
								<th>Assignee<br/>
									<span class="badge badge-primary" @click="copyColumn(3)">Copy</span>
								</th>
								<th>Reporter<br/>
									<span class="badge badge-primary" @click="copyColumn(4)">Copy</span>
								</th>
								<th>Priority<br/>
									<span class="badge badge-primary" @click="copyColumn(5)">Copy</span>
								</th>
								<th>Status<br/>
									<span class="badge badge-primary" @click="copyColumn(6)">Copy</span>
								</th>
								<th>Sprint<br/>
									<span class="badge badge-primary" @click="copyColumn(7)">Copy</span>
								</th>
							</tr>
							</thead>
							
							<tbody>
								<tr v-for="(issue, index) in issuesList" :key="index">
									<td class="issuetype">{{issue.fields.issuetype.name}}</td>
									<td class="key">{{issue.key}}</td>
									<td class="summary">{{issue.fields.summary}}</td>
									<td class="assignee">{{issue.fields.assignee.displayName}}</td>
									<td class="reporter">{{issue.fields.reporter.displayName}}</td>
									<td class="priority">{{issue.fields.priority.name}}</td>
									<td class="status">{{issue.fields.status.name}}</td>
									<td>{{ getSprint(issue.fields.customfield_10003) }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</main>
		</div>
	</div>
</template>

<script>
import axios from 'axios'; // Import Axios

// @ is an alias to /src

export default {
	name: 'JiraEntries',
	components: {
		
	},
	data () {
		return {
			jiraIssues: false,
			jiraId: this.$route.params.jiraid || ''
		}
	},
	computed: {
		issuesList() {
			return this.jiraIssues
		}
	},
	methods: {
		async fetchJiraIssues() {
			try {
				const proxyUrl =  process.env.VUE_APP_SERVER_URL
				const response = await axios.get(`${proxyUrl}/jira?jiraId=${this.jiraId}`);
				if (response.error) {
					console.log('Please check the access token you have provided.')
				}
				this.jiraIssues = response?.data?.issues || [];
			} catch (error) {
				console.error(error);
			}
		},
		copyColumn(columnIndex) {
			const toast = this.$toast
			const table = document.querySelector('.jira-issues');
			let columnContent = '';
			for (let i = 1; i < table.rows.length; i++) { // Start from 1 to ignore the header row
				const row = table.rows[i];
				const cell = row.cells[columnIndex];
				columnContent += `${cell.textContent}\n`;
			}
			navigator.clipboard.writeText(columnContent).then(function() {
				console.log('Copying to clipboard was successful!');
				toast.success(`Copied to clipboard !!!`, { position: 'bottom-right' });
			}, function(err) {
				toast.error(`Copy failed: ` + err, { position: 'bottom-right' });
			});
		},
		getSprint(sprint) {
			return (sprint || []).map(item => item.name).join(', ')
		}
	},
	created () {
		return this.fetchJiraIssues()
	},
	beforeRouteUpdate(to, from, next) {
		next();
	}
}
</script>
<style scoped>
th .badge{
	cursor: pointer;
}
</style>
